import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { useCookies } from 'react-cookie';
import SEO from "../components/seo/seo"
import Layout from "../components/layout/layout"
import AppBar from "../components/appBar/appBar"
import MainSection from "../components/mainSection/mainSection"
import SecondarySection from "../components/secondarySection/secondarySection"
import PageSection from "../components/pageSection/pageSection"
import TaxesForm from "../components/taxesForm/taxesForm"
import Card from "../components/card/card";
import OptOut from "../components/optOut/optOut"
import IFrame from "../components/iFrame/iFrame"
import Modal from "../components/modal/modal"

import * as ACTIONS from "../state/actions"

const tcPdf = <IFrame file="../TC.pdf" title="Términos de uso"/>;
const pcPdf = <IFrame file="../PC.pdf" title="Política de privacidad"/>;
// const footerDesc = "";
const contentMaxWidth = "980px";

const Impuestos = () => {
    const appState = useSelector(state => state.appReducer);
    const dispatch = useDispatch();

    const [cookies, setCookie] = useCookies(['consent']);

    const taxesFormHandler = () => {
        window.scroll({ top: 0, left: 0, behavior: "smooth" });

        const modal = {
            display: true,
            content: <TaxesForm lang={appState.lang} title="¡Gracias por tu interés!" subtitle="Estamos apunto de lanzar 🚀! Regístrate y solicita tu acceso preferencial cuanto antes." amplitude={appState.amplitude} amplitudeClickEvent="PreSignUpSATCreated"/>
        };

        dispatch(ACTIONS.showModal(modal))
    };

    const termsUseHandler = () => {
        window.scroll({ top: 0, left: 0, behavior: "smooth" });
        dispatch(ACTIONS.showModal({display: true, content: tcPdf }))
    };

    const privacyPolicyHandler = () => {
        window.scroll({ top: 0, left: 0, behavior: "smooth" });
        dispatch(ACTIONS.showModal({display: true, content: pcPdf }))
    };

    const cookieHandler = () => {
        setCookie("consent", "dismiss", { path: '/' });
    };

    const closeModal = () => dispatch(ACTIONS.hideModal());

    return (
        <Layout lang={appState.lang} privacyPolicyAction={privacyPolicyHandler} termsUseHandlerAction={termsUseHandler}>
            <SEO lang={appState.lang} page="impuestos-sat" title="PyME, Startup, Freelancer, No te estreses más por tus impuestos del SAT" description="EVVA te da una visión clara de tu información fiscal en SAT, útiles recordatorios y estimaciones de impuestos con base a tus ganancias."/>
            {appState.modal.display && <Modal content={appState.modal.content} closeModal={closeModal}/> }
            {!cookies.consent &&
            <OptOut text="Utilizamos cookies para garantizar que tengas la mejor experiencia en nuestro sitio web." privacyPolicyAction={privacyPolicyHandler} privacyPolicyLabel="Ver Política de privacidad" cookieAction={cookieHandler}/>
            }
            <MainSection title="PyME, Startup, Freelancer, No te estreses más por tus impuestos." description="<h3>EVVA te da una visión clara de tu información fiscal, útiles recordatorios y estimaciones de impuestos con base a tus ganancias.</h3>" position="left" image="hero-sat.png" actionLabel="Solicita tu invitación" action={taxesFormHandler} amplitude={appState.amplitude} amplitudeClickEvent="ClickPreSignUpSAT">
                <AppBar pathname={appState.path} />
            </MainSection>
            <PageSection title="Mantente adelante del SAT con EVVA" maxWidth={contentMaxWidth}>
                <Card
                    title="No te retrases nunca más"
                    description="<p>Conecta tu cuenta de SAT con EVVA.<br /> te enviaremos recordatorios cada vez que recibas una nueva factura o tu declaración de impuestos.</p>"
                    descriptionColor="#363636"
                    image="deadline-taxes.png"
                    imageWidth="260px"
                    imageHeight="280px"
                    marginTopContent="60px"
                    horizontal
                    padding
                />
                <Card
                    title="Mantén tus impuesto bajo control"
                    description="<p>EVVA te ayudará a calcular cuántos impuestos deberás pagar con base a tus ganacias.</p>"
                    descriptionColor="#363636"
                    image="income-tax.svg"
                    imageWidth="260px"
                    imageHeight="280px"
                    marginTopContent="60px"
                    reverseDirection
                    horizontal
                    padding
                />
                <Card
                    title="Toma las mejores decisiones"
                    description="<p>Visualiza de forma sencilla el flujo de caja de efectivo basado en tu facturación.</p><p>Identifica en dónde gastas más, optimiza y reduce tus costos.</p>"
                    descriptionColor="#363636"
                    image="better-tax-decision.png"
                    imageWidth="260px"
                    imageHeight="280px"
                    marginTopContent="40px"
                    horizontal
                    padding
                />
            </PageSection>
            <PageSection maxColumns={4} title="Descubre EVVA marketplace" description="Tenemos todos los servicios digital que hacer más sencilla tu vida financiera de cada día.">
                <Card
                    subtitle="Cuenta empresarial"
                    image="logo.svg"
                    imageWidth="180px"
                    imageHeight="200px"
                    imageBgColor="_evva"
                    link="/"
                    linkLabel="Conoce más"
                    imageShadow
                />
                <Card
                    subtitle="EVVA lealtad digital"
                    image="logo-evva-wallet.svg"
                    imageWidth="180px"
                    imageHeight="200px"
                    imageBgColor="_evva"
                    link="/wallet"
                    linkLabel="Conoce más"
                    imageShadow
                />
                <Card
                    subtitle="Financiamiento PyMe"
                    image="logo-uniclick-white.png"
                    imageWidth="180px"
                    imageHeight="200px"
                    imageBgColor="_uniclick"
                    link="/financiamiento"
                    linkLabel="Conoce más"
                    imageShadow
                />
                <Card
                    subtitle="Transacciones seguras"
                    image="logo-fido.png"
                    imageWidth="180px"
                    imageHeight="200px"
                    imageBgColor="_fido"
                    link="https://www.escrow.fido.mx/"
                    linkLabel="Conoce más"
                    imageShadow
                />
            </PageSection>
            <SecondarySection
                title="Juntos crecemos"
                shortDescription="De emprendedores para emprendedores. Somos gente como tú que entiende tus necesidades. Nuestro objetivo final es que te enfoques en lo que realmente importa: tu empresa."
                position="center"
                image="evva-together.jpg"
                actionLabel="Solicita tu invitación"
                action={taxesFormHandler}
                amplitude={appState.amplitude}
                amplitudeClickEvent="ClickPreSignUpSAT"
            />
        </Layout>
    )
};

export default Impuestos